import React, { useState, useEffect } from "react";
import ContactUs from "../Calculators/ContactUs";
import { Footer } from "../Footer";
import { Hero } from "./Hero";
import Layout from "./Layout";
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import NavbarMobile from "../NavbarMobile";
import ContactUsMobile from "./ContactUsMobile";
import Banner3 from "./Banner3";
import { Helmet } from "react-helmet";

export const ThreeDMain = () => {
  const [viewport, setViewport] = useState("large");
    const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch all meta tags for ThreeDPlans
    fetch("https://api.bhk99.com/api/threedplansmetatags")
      .then((response) => response.json())
      .then((data) => {
        setMetaTags(data || []); // Use all meta tags
        console.log("Fetched ThreeDPlans meta tags:", data);
      })
      .catch((error) => console.error("Error fetching ThreeDPlans meta tags:", error));
  }, []);

  return (
    <>
  
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <Hero />
      <Layout />
      <ContactUs />
      {viewport === "mobile" && <ContactUsMobile />}
      <Banner3 />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};

export default ThreeDMain;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";

const AddSubService = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [subServiceName, setSubServiceName] = useState("");
  const [subServiceImage, setSubServiceImage] = useState("");
  const [subserviceInfo, setSubserviceInfo] = useState("<p></p>"); // Default Quill value
  const [headerMetaTag, setHeaderMetaTag] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/services");
        setServices(response.data.services || []);
      } catch (error) {
        toast.error("Error fetching services");
      }
    };

    fetchServices();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSubServiceImage(reader.result); // Base64 string for image
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedService || !subServiceName || !subServiceImage || !subserviceInfo) {
      toast.error("All fields are required except header meta tag");
      return;
    }

    const payload = {
      serviceName: selectedService,
      subServiceName,
      subServiceImage,
      subserviceInfo,
      headerMetaTag,
    };

    try {
      const response = await axios.post("https://api.bhk99.com/api/subservices", payload);
      if (response.status === 201) {
        toast.success("Subservice added successfully");
        setSelectedService("");
        setSubServiceName("");
        setSubServiceImage("");
        setSubserviceInfo("<p></p>");
        setHeaderMetaTag("");
      }
    } catch (error) {
      toast.error(`Error adding subservice: ${error.response?.data?.error || error.message}`);
    }
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add Subservice</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="service" className="block text-gray-700 font-medium mb-2">
            Select a Service
          </label>
          <select
            id="service"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
          >
            <option value="">Select a service</option>
            {services.map((service) => (
              <option key={service.name} value={service.name}>
                {service.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="subServiceName" className="block text-gray-700 font-medium mb-2">
            Subservice Name
          </label>
          <input
            id="subServiceName"
            type="text"
            value={subServiceName}
            onChange={(e) => setSubServiceName(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="subServiceImage" className="block text-gray-700 font-medium mb-2">
            Subservice Image
          </label>
          <input
            id="subServiceImage"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="subserviceInfo" className="block text-gray-700 font-medium mb-2">
            Subservice Information
          </label>
          <ReactQuill
            value={subserviceInfo}
            onChange={setSubserviceInfo}
            className="bg-white"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="headerMetaTag" className="block text-gray-700 font-medium mb-2">
            Header Meta Tag (Optional)
          </label>
          <input
            id="headerMetaTag"
            type="text"
            value={headerMetaTag}
            onChange={(e) => setHeaderMetaTag(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
        >
          Add Subservice
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddSubService;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Navbar2 } from "../Navbar2";

import NavbarMobile from "../NavbarMobile";
import { Footer } from "../Footer";

import Subservices from "./Subservices";
import { NavbarTab } from "../NavbarTab";

const MainServicePage = () => {
  const { serviceId } = useParams(); // Capture serviceId from the route
  const [viewport, setViewport] = useState("large");
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {

 const fetchMetaTags = async () => {
      try {
        const response = await fetch("https://api.bhk99.com/api/subservicesmetatags");
        const data = await response.json();
        setMetaTags(data || []);
        console.log("Fetched Subservices meta tags:", data);
      } catch (error) {
        console.error("Error fetching meta tags:", error);
      }
    };

    fetchMetaTags();



    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        {metaTags.map((tag, index) => (
          <meta
            key={index}
            dangerouslySetInnerHTML={{ __html: tag.metaTag }}
          />
        ))}
      </Helmet>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <Subservices />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}{" "}
    </div>
  );
};

export default MainServicePage;

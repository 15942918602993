// src/admin/ActiveGoogleReview.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ActiveGoogleReview = () => {
  const [reviews, setReviews] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/googleReview/get');
        setReviews(response.data);
      } catch (error) {
        setMessage('Error fetching reviews');
      }
    };
    fetchReviews();
  }, []);

  const handleDeleteReview = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/api/googleReview/review/${id}`);
      setReviews(reviews.filter((review) => review._id !== id));
    } catch (error) {
      setMessage('Error deleting review');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold text-center">Active Google Reviews</h2>
      {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      <div className="space-y-4 mt-4">
        {reviews.map((review) => (
          <div key={review._id} className="border p-4 rounded-md">
            <iframe
              src={review.reviewLink}
              width="100%"
              height="300"
              title="Google Review"
              className="border-0 rounded-md"
            />
            <button
              onClick={() => handleDeleteReview(review._id)}
              className="mt-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md"
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveGoogleReview;

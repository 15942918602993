import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the necessary CSS for the editor

const ActiveSubService = () => {
  const [subServices, setSubServices] = useState([]);
  const [services, setServices] = useState([]);
  const [editingSubservice, setEditingSubservice] = useState(null);
  const [formData, setFormData] = useState({
    serviceName: "",
    subServiceName: "",
    subserviceInfo: "", // This will store the Quill editor content
    subServiceImage: null,
    serviceName: "", // Store selected service name
    headerMetaTag: "", // New field
  });

  // Fetch subservices and services
  useEffect(() => {
    const fetchSubServices = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/subservices");
        setSubServices(response.data);
      } catch (error) {
        console.error("Error fetching subservices:", error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/api/services");
        setServices(response.data.services || []);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchSubServices();
    fetchServices();
  }, []);

  // Handle edit button click
  const handleEdit = (subService) => {
    setEditingSubservice(subService._id);
    setFormData({
      serviceName: subService.serviceName || "", // Populate selected service
      subServiceName: subService.subServiceName || "",
      subserviceInfo: subService.subserviceInfo || "",
      subServiceImage: subService.subServiceImage || null,
      serviceName: subService.serviceName || "", // Populate selected service
      headerMetaTag: subService.headerMetaTag || "", // Populate headerMetaTag
    });
  };

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file input changes (convert to base64)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prev) => ({ ...prev, subServiceImage: reader.result }));
    };
    reader.readAsDataURL(file);
  };

  // Handle Quill editor change
  const handleQuillChange = (value) => {
    setFormData((prev) => ({ ...prev, subserviceInfo: value }));
  };

  // Handle update submission
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const updatedData = {
        serviceName: formData.serviceName,
        subServiceName: formData.subServiceName,
        subserviceInfo: formData.subserviceInfo,
        subServiceImage: formData.subServiceImage,
        serviceName: formData.serviceName, // Include selected service name
        headerMetaTag: formData.headerMetaTag, // Include headerMetaTag
      };

      // Update subservice in the database
      const response = await axios.put(
        `https://api.bhk99.com/api/subservices/${editingSubservice}`,
        updatedData
      );

      // Update local state to reflect changes after successful update
      setSubServices((prev) =>
        prev.map((subService) =>
          subService._id === editingSubservice ? response.data : subService
        )
      );

      toast.success("Subservice updated successfully!");
      setEditingSubservice(null); // Close the edit form
    } catch (error) {
      console.error("Error updating subservice:", error);
      toast.error("Error updating subservice.");
    }
  };

  // Handle delete button click
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/api/subservices/${id}`);
      toast.success("Subservice deleted successfully!");
      setSubServices((prev) => prev.filter((subService) => subService._id !== id));
    } catch (error) {
      console.error("Error deleting subservice:", error);
      toast.error("Error deleting subservice.");
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Active Subservices</h1>

      {editingSubservice ? (
        <div className="mb-4 p-4 border rounded">
          <h2 className="text-xl font-semibold mb-4">Editing {formData.subServiceName}</h2>
          <form onSubmit={handleUpdate}>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Subservice Name</label>
              <input
                type="text"
                name="subServiceName"
                value={formData.subServiceName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Select Service</label>
              <select
                name="serviceName"
                value={formData.serviceName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select a service</option>
                {services.map((service) => (
                  <option key={service._id} value={service.name}>
                    {service.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Subservice Info</label>
              <ReactQuill
                value={formData.subserviceInfo}
                onChange={handleQuillChange}
                className="w-full border rounded"
                theme="snow"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Header Meta Tag</label>
              <input
                type="text"
                name="headerMetaTag"
                value={formData.headerMetaTag}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
               
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Subservice Image</label>
              {formData.subServiceImage &&
              typeof formData.subServiceImage === "string" &&
              formData.subServiceImage.startsWith("data:image") ? (
                <img
                  src={formData.subServiceImage}
                  alt="Subservice"
                  className="mb-2 w-32 h-32 object-cover border"
                />
              ) : null}
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
              >
                Update Subservice
              </button>
              <button
                type="button"
                onClick={() => setEditingSubservice(null)}
                className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          {subServices.map((subService) => (
            <div
              key={subService._id}
              className="p-4 mb-4 border rounded flex justify-between items-center"
            >
              <div>
                <h2 className="text-lg font-semibold">{subService.subServiceName}</h2>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => handleEdit(subService)}
                  className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(subService._id)}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default ActiveSubService;

import React from 'react';

function ContactUsSubmission() {
  return (
    <div className="text-center mt-10">
      <h2 className="text-2xl font-semibold text-gray-700">Details Sent Successfully of Contact Us Form</h2>
      <p className="mt-4 text-lg">Thank you for reaching out to us. We will get back to you soon!</p>
    </div>
  );
}

export default ContactUsSubmission;

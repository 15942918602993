// bodyContentUtils.js

export async function fetchAndAddBodyContent(apiUrl) {
    try {
      // Fetch data from the provided API URL
      const response = await fetch(apiUrl);
  
      if (!response.ok) {
        throw new Error(`Error fetching body content: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Log the fetched data to inspect its structure
      console.log(`Fetched body content from ${apiUrl}:`, data);
  
      // Check if the data is an array and contains an object with the 'code' field
      if (Array.isArray(data) && data.length > 0 && data[0].code) {
        // Add the 'code' content to the body
        appendBodyContentToBody(data[0].code, 'globalBodyCode');
      } else {
        console.error(`No 'code' field found in the response from ${apiUrl}`);
      }
    } catch (error) {
      console.error(`Failed to fetch and update body content from ${apiUrl}:`, error);
    }
  }
  
  // Helper function to append the fetched 'code' content to the body section
  function appendBodyContentToBody(codeString, source) {
    // Create a new div element to hold the content
    const divElement = document.createElement('div');
    divElement.innerHTML = codeString;
  
    // Append the content to the body
    document.body.appendChild(divElement);
    console.log(`Body content from ${source} added to body:`, divElement.outerHTML);
  }
  
import React, { useState } from "react";

const ServiceDetails = ({ service }) => {
  const [imageError, setImageError] = useState(false);

  // Display a message if the service is not found
  if (!service) {
    return <div className="text-center text-red-500">Service not found</div>;
  }

  // Determine if the image source is base64 or a URL
  const isBase64 = (str) => /^data:image\/[a-zA-Z]+;base64,/.test(str);

  const imageUrl = isBase64(service.image)
    ? service.image
    : `data:image/jpeg;base64,${service.image}`;

  return (
    <div className="service-details">
      <div className="relative">
        <img
          src={imageUrl}
          alt={service.title}
          className={`w-full h-[50vh] object-cover ${imageError ? "hidden" : ""}`}
          onError={() => setImageError(true)}
        />
        {imageError && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
            <p className="text-lg text-gray-500">Image could not be loaded.</p>
          </div>
        )}
        <h1 className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-4xl font-bold bg-black bg-opacity-50 px-2 py-2 rounded-2xl md:ml-4 lg:ml-8">
          {service.title}
        </h1>
      </div>

      <div className="container mx-auto px-4 py-8 text-left">
        <h2 className="text-3xl font-semibold mb-4 text-center">{service.title}</h2>
        <div
          className="text-left lg:ml-16 lg:mr-16 leading-relaxed"
          dangerouslySetInnerHTML={{
            __html: service.description.replace(
              /<ul>/g,
              '<ul class="list-disc pl-8">'
            ),
          }} // Ensure <ul> has appropriate list styles
        />
      </div>
    </div>
  );
};

export default ServiceDetails;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveContactUsMetaTag = () => {
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    fetch("https://api.bhk99.com/api/contactusmetatags")
      .then((response) => response.json())
      .then((data) => setMetaTags(data))
      .catch((error) => console.error("Error fetching meta tags for Contact Us page:", error));
  }, []);

  const handleDelete = (id) => {
    fetch(`https://api.bhk99.com/api/contactusmetatags/${id}`, { method: "DELETE" })
      .then(() => {
        setMetaTags(metaTags.filter((tag) => tag._id !== id));
        toast.success("Meta tag for Contact Us page deleted successfully!");
      })
      .catch((error) => {
        console.error("Error deleting meta tag for Contact Us page:", error);
        toast.error("Error deleting meta tag. Please try again.");
      });
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Active Meta Tags for Contact Us Page
      </h2>
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-4">
        {metaTags.length > 0 ? (
          <ul className="space-y-4">
            {metaTags.map((tag) => (
              <li
                key={tag._id}
                className="p-4 bg-gray-50 rounded-lg flex items-center justify-between border border-gray-200 shadow-sm"
              >
                <div className="text-gray-700 font-medium">{tag.metaTag}</div>
                <button
                  onClick={() => handleDelete(tag._id)}
                  className="px-4 py-2 text-sm text-white bg-red-500 rounded-lg hover:bg-red-600 transition-all"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 text-center">
            No active meta tags found for Contact Us page. Add some to get started!
          </p>
        )}
      </div>
    </div>
  );
};

export default ActiveContactUsMetaTag;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveExtraCode = () => {
  const [scripts, setScripts] = useState([]);
  const [editingScript, setEditingScript] = useState(null);
  const [editCode, setEditCode] = useState("");

  useEffect(() => {
    fetchScripts();
  }, []);

  const fetchScripts = async () => {
    try {
      const response = await fetch("https://api.bhk99.com/api/extraCode");
      const data = await response.json();
      setScripts(data);
    } catch (error) {
      console.error("Error fetching scripts:", error);
      toast.error("Failed to fetch scripts.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api.bhk99.com/api/extraCode/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        toast.success("Script deleted successfully!");
        fetchScripts();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || "Failed to delete script.");
      }
    } catch (error) {
      console.error("Error deleting script:", error);
      toast.error("An error occurred while deleting the script.");
    }
  };

  const handleEdit = (script) => {
    setEditingScript(script);
    setEditCode(script.scriptCode);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`https://api.bhk99.com/api/extraCode/${editingScript._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ scriptCode: editCode }),
      });

      if (response.ok) {
        toast.success("Script updated successfully!");
        setEditingScript(null);
        setEditCode("");
        fetchScripts();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || "Failed to update script.");
      }
    } catch (error) {
      console.error("Error updating script:", error);
      toast.error("An error occurred while updating the script.");
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">Active Scripts</h2>
      <ul className="space-y-4">
        {scripts.map((script) => (
          <li key={script._id} className="p-4 bg-gray-100 rounded-md">
            {editingScript?._id === script._id ? (
              <div>
                <textarea
                  className="w-full p-3 border border-gray-300 rounded-md"
                  value={editCode}
                  onChange={(e) => setEditCode(e.target.value)}
                  rows="4"
                />
                <div className="mt-2 flex space-x-4">
                  <button
                    onClick={handleUpdate}
                    className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => setEditingScript(null)}
                    className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <pre className="whitespace-pre-wrap">{script.scriptCode}</pre>
                <div className="mt-2 flex space-x-4">
                  <button
                    onClick={() => handleEdit(script)}
                    className="px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(script._id)}
                    className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActiveExtraCode;

import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const AddExtraCode = () => {
  const [scriptCode, setScriptCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.bhk99.com/api/extraCode/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ scriptCode }),
      });

      if (response.ok) {
        toast.success("Script added successfully!");
        alert("Script added successfully!");
        setScriptCode(""); // Reset the form
      } else {
        toast.error("Failed to add script");
      }
    } catch (error) {
      console.error("Error adding script:", error);
      toast.error("An error occurred while adding the script.");
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add Extra Code</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={scriptCode}
          onChange={(e) => setScriptCode(e.target.value)}
          rows="8"
          placeholder="Enter extra code or script tag"
        />
        <button
          type="submit"
          className="mt-4 px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
        >
          Add Script
        </button>
      </form>
    </div>
  );
};

export default AddExtraCode;

import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddSubservicesMetaTag = () => {
  const [metaTag, setMetaTag] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://api.bhk99.com/api/subservicesmetatags", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ metaTag }),
    })
      .then((response) => response.json())
      .then(() => {
        alert("Meta tag added successfully!");
        toast.success("Meta tag for Subservices page added successfully!");
        setMetaTag("");
      })
      .catch((error) => {
        console.error("Error adding meta tag for Subservices page:", error);
        toast.error("Error adding meta tag. Please try again.");
      });
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <label className="block mb-2">Add Meta Tag for Subservices Page (HTML Code):</label>
      <textarea
        value={metaTag}
        onChange={(e) => setMetaTag(e.target.value)}
        className="w-full border p-2"
        rows="5"
        placeholder="<meta name='description' content='Your meta description for Subservices page here' />"
      />
      <button type="submit" className="bg-orange-500 text-white px-4 py-2 mt-2 hover:bg-orange-600">
        Add Meta Tag
      </button>
    </form>
  );
};

export default AddSubservicesMetaTag;

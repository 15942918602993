import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { fetchAndAddMetaTags } from './components/metaTagUtils';
import { fetchAndAddBodyContent } from './components/bodyContentUtils';

// Function to update all meta tags
async function updateAllMetaTags() {
  // Define an array of API endpoints for meta tags
  const metaTagApis = [
    'https://api.bhk99.com/api/globalmetatags',       // Global Head meta tags
    'https://api.bhk99.com/api/metatags',            // Home page
    'https://api.bhk99.com/api/threedplansmetatags', // ThreeDPlan page
    'https://api.bhk99.com/api/ourprojectmetatags',  // OurProjects page
    'https://api.bhk99.com/api/costestimatormetatags', // CostEstimator page
    'https://api.bhk99.com/api/howitworksmetatags',  // HowItWorks page
    'https://api.bhk99.com/api/contactusmetatags',   // ContactUs page
    'https://api.bhk99.com/api/subservicesmetatags', // Subservices page
    'https://api.bhk99.com/api/subservices/header/headerMetaTags', // Subservices header meta tags
  ];

  // Fetch and add meta tags for each API
  for (const apiUrl of metaTagApis) {
    await fetchAndAddMetaTags(apiUrl);
  }
}

// Function to fetch and add body content (code) from globalBody API
async function updateBodyContent() {
  const bodyContentApiUrl = 'https://api.bhk99.com/api/globalBody'; // API endpoint for body content
  await fetchAndAddBodyContent(bodyContentApiUrl);
}

// Call the functions to update meta tags and body content
Promise.all([updateAllMetaTags(), updateBodyContent()])
  .then(() => {
    console.log('All meta tags and body content have been updated.');
  });

// Render the React app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Measure performance
reportWebVitals();

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Banner } from "./Banner";
import { Hero } from "./Hero";
import HowItWorks from "./HowItWorks";
import OurServices from "./OurServices";
import Pricing from "./Pricing";
import { Projects } from "./Projects";
import { WhyUs } from "./WhyUS";
import Customers from "./Customers";
import Banner2 from "./Banner2";
import Profit from "./Profit";
import Banking from "./Banking";
import News from "./News";
import FAQ from "./FAQ";
import { Footer } from "./Footer";
import { Navbar2 } from "./Navbar2";
import NavbarMobile from "./NavbarMobile"; // Import NavbarMobile component
import { NavbarTab } from "./NavbarTab";
import { ProjectMobile } from "./ProjectMobile";
import { HowItWorksMobile } from "./HowItWorksMobile";
import PricingMobile from "./PricingMobile";
import GoogleReview from "./GoogleReview";


export const Home = () => {
  const [viewport, setViewport] = useState("large");
  const [metaTags, setMetaTags] = useState([]);
  const [extraScript, setExtraScript] = useState("");
  
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    // Fetch all meta tags from the database
    fetch("https://api.bhk99.com/api/metatags")
      .then((response) => response.json())
      .then((data) => {
        setMetaTags(data || []); // Use all meta tags
        console.log("Fetched meta tags:", data);
      })
      .catch((error) => console.error("Error fetching meta tags:", error));
  }, []);

  useEffect(() => {
    // Fetch extra script code from the database
    fetch("https://api.bhk99.com/api/extracode")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setExtraScript(data[data.length - 1].scriptCode); // Fetch the latest script code
        }
      })
      .catch((error) => console.error("Error fetching script:", error));
  }, []);


  return (
    <>
   
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <Hero />
      <OurServices />
      <WhyUs />
      {viewport === "mobile" && <ProjectMobile />}
      <Projects />
      <HowItWorks />
      {viewport === "mobile" && <HowItWorksMobile />}
      {viewport === "mobile" && <PricingMobile />}
      <Pricing />
      
      <Banner />
      <Customers />
      <GoogleReview/>
      <Banner2 />
      <Profit />
      <Banking />
      <News />
      <FAQ />
      {extraScript && <div dangerouslySetInnerHTML={{ __html: extraScript}} />}
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}{" "}
    </>
  );
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ServiceDetails from "../Service Details/ServiceDetails";

const AddServiceDetailsHome = () => {
  const { subServiceName } = useParams(); // Extract subServiceName from URL
  const [subServiceDetails, setSubServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubServiceDetails = async () => {
      try {
        // Fetch data using the subServiceName from URL
        const response = await axios.get(
          `https://api.bhk99.com/api/subservices/${subServiceName}` // This should now match the backend route
        );
        if (response.status === 200 && response.data) {
          const { subServiceName, subserviceInfo, subServiceImage } = response.data;
          setSubServiceDetails({
            title: subServiceName,
            description: subserviceInfo,
            image: subServiceImage,
          });
        } else {
          setError("Subservice not found");
        }
      } catch (error) {
        console.error("Error fetching subservice details:", error);
        setError("Error fetching subservice details");
      } finally {
        setLoading(false);
      }
    };

    if (subServiceName) {
      fetchSubServiceDetails();
    } else {
      setError("Subservice name is not defined");
      setLoading(false);
    }
  }, [subServiceName]); // Depend on subServiceName

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return <ServiceDetails service={subServiceDetails} />;
};

export default AddServiceDetailsHome;

import React, { useState } from 'react';
import axios from 'axios';

const AddGoogleReview = () => {
  const [reviewLink, setReviewLink] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.bhk99.com/api/googleReview/add', { reviewLink });
      alert('Review added successfully!');
    } catch (error) {
      console.error('Error adding review:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold">Add Google Review</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mt-4">
        <div>
          <label className="block text-gray-700">Google Review Embed Link</label>
          <input
            type="text"
            value={reviewLink}
            onChange={(e) => setReviewLink(e.target.value)}
            placeholder="Paste Google Review Embed Code"
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button type="submit" className="bg-orange-500 text-white p-2 rounded mt-4 hover:bg-orange-600">
          Add Review
        </button>
      </form>
    </div>
  );
};

export default AddGoogleReview;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActiveGlobalBody = () => {
  const [globalBodies, setGlobalBodies] = useState([]);
  const [editData, setEditData] = useState(null);

  const fetchGlobalBodies = async () => {
    try {
      const response = await fetch('https://api.bhk99.com/api/globalBody');
      const data = await response.json();
      setGlobalBodies(data);
    } catch (error) {
      toast.error('Failed to fetch global bodies');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api.bhk99.com/api/globalBody/${id}`, { method: 'DELETE' });
      if (response.ok) {
        toast.success('Global body deleted successfully');
        fetchGlobalBodies();
      } else {
        toast.error('Failed to delete global body');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.bhk99.com/api/globalBody/${editData._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: editData.code }),
      });
      if (response.ok) {
        toast.success('Global body updated successfully');
        setEditData(null);
        fetchGlobalBodies();
      } else {
        toast.error('Failed to update global body');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    fetchGlobalBodies();
  }, []);

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-md shadow-md">
      <h1 className="text-2xl font-bold mb-4">Active Global Bodies</h1>
      {globalBodies.map((body) => (
        <div
          key={body._id}
          className="border border-gray-300 rounded-md p-4 mb-4 shadow-sm"
        >
          <pre className="bg-gray-100 p-2 rounded-md">{body.code}</pre>
          <div className="mt-4">
            <button
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-1 px-3 rounded mr-2"
              onClick={() => setEditData(body)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 rounded"
              onClick={() => handleDelete(body._id)}
            >
              Delete
            </button>
          </div>
        </div>
      ))}
      {editData && (
        <form onSubmit={handleEditSubmit} className="mt-6 p-4 bg-gray-100 rounded-md">
          <h2 className="text-xl font-bold mb-4">Edit Global Body</h2>
         
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Code</label>
            <textarea
              className="w-full border border-gray-300 rounded-md p-2"
              value={editData.code}
              onChange={(e) => setEditData({ ...editData, code: e.target.value })}
              rows={6}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Save Changes
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => setEditData(null)}
          >
            Cancel
          </button>
        </form>
      )}
    </div>
  );
};

export default ActiveGlobalBody;

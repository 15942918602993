import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddGlobalBody = () => {
  const [code, setCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.bhk99.com/api/globalBody/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code }),
      });
      if (response.ok) {
        alert("added to body successfully!");
        toast.success('Global body added successfully');
        setCode('');
      } else {
        toast.error('Failed to add global body');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <div className="p-6 max-w-3xl mx-auto bg-white rounded-md shadow-md">
      <h1 className="text-2xl font-bold mb-4">Add Global Body</h1>
      <form onSubmit={handleSubmit}>
        
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Code</label>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            rows={6}
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-orange-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-orange-600"
        >
          Add Global Body
        </button>
      </form>
    </div>
  );
};

export default AddGlobalBody;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GoogleReview = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/googleReview/get');
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    fetchReviews();
  }, []);

  return (
    <div className="container mx-auto p-4 lg:ml-12">
      <h2 className="text-4xl font-semibold font-poppins mb-4 text-center">Our Google Reviews</h2>
      <div className="space-y-4 mt-4">
        {reviews.map((review) => (
          <div key={review._id} className="border p-4 rounded-md">
            <div dangerouslySetInnerHTML={{ __html: review.reviewLink }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleReview;

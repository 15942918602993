import React, { useState, useEffect } from "react";
import Banner3 from "./Banner3";
import { CalciTile } from "./CalciTile";
import { HeroCalci } from "./HeroCalci";
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import { Footer } from "../Footer";
import NavbarMobile from "../NavbarMobile";
import { Helmet } from "react-helmet";

export const Main = () => {
  const [viewport, setViewport] = useState("large");
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await fetch("https://api.bhk99.com/api/costestimatormetatags");
        const data = await response.json();
        setMetaTags(data || []);
        console.log("Fetched Cost Estimator meta tags:", data);
      } catch (error) {
        console.error("Error fetching meta tags:", error);
      }
    };

    fetchMetaTags();


    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
   
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <HeroCalci />
      <CalciTile />
      <Banner3 />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};

import React, { useState, useEffect } from "react";
import WalkthroughsSection from "./OurProjectWalkthroughsSection";
import ProjectsSection from "./OurProjectProjectsSection";
import ContactForm from "./OurProjectContactForm";
import Banner2 from "./OurProjectBanner2";
// import Banner from "./OurProjectBanner";
import { Navbar2, OurProjectNavbar2 } from "./OurProjectNavbar2";
import { NavbarTab, OurProjectNavbarTab } from "./OurProjectNavbarTab";
import NavbarMobile from "./OurProjectNavbarMobile";
import { Footer, OurProjectFooter } from "./OurProjectFooter";
import OurProjectWalkthroughsSection from "./OurProjectWalkthroughsSection";
import OurProjectProjectsSection from "./OurProjectProjectsSection";
import OurProjectContactForm from "./OurProjectContactForm";
import OurProjectBanner2 from "./OurProjectBanner2";
import OurProjectBanner from "./OurProjectBanner";
import OurProjectNavbarMobile from "./OurProjectNavbarMobile";
import ContactUsMobile from "../Calculators/ContactUsMobile";
import ContactUs from "../Calculators/ContactUs";
import { ProjectMobile } from "../ProjectMobile";
import { Banner } from "./Banner";
import { Helmet } from "react-helmet";

const OurProject = () => {
  const [viewport, setViewport] = useState("large");
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await fetch("https://api.bhk99.com/api/ourprojectmetatags");
        const data = await response.json();
        setMetaTags(data || []);
        console.log("Fetched OurProject meta tags:", data);
      } catch (error) {
        console.error("Error fetching meta tags:", error);
      }
    };

    fetchMetaTags();




    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      
      
      {viewport === "large" && <OurProjectNavbar2 />}
      {viewport === "medium" && <OurProjectNavbarTab />}
      <OurProjectWalkthroughsSection />
      {viewport === "mobile" && <ProjectMobile />}
      {viewport === "large" && <OurProjectProjectsSection />}
      {viewport === "medium" && <OurProjectProjectsSection />}
      <ContactUs />
      {viewport === "mobile" && <ContactUsMobile />}
      <OurProjectBanner2 />
      {viewport === "large" && <Banner />}
      <OurProjectFooter />
      {viewport === "mobile" && <OurProjectNavbarMobile />}
    </>
  );
};
export default OurProject;

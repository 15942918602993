export async function fetchAndAddMetaTags(apiUrl) {
    try {
      // Fetch data from the provided API URL
      const response = await fetch(apiUrl);
  
      if (!response.ok) {
        throw new Error(`Error fetching meta tags: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Log the fetched data to inspect its structure
      console.log(`Fetched meta tag data from ${apiUrl}:`, data);
  
      // Check if the data is an array and contains meta tags
      if (Array.isArray(data) && data.length > 0) {
        // If it's the Subservices header meta tags, directly add to head
        if (apiUrl.includes('subservices/header/headerMetaTags')) {
          data.forEach((metaTagString) => {
            appendMetaTagsToHead(metaTagString, 'subservicesHeaderMetaTag');
          });
        } else {
          // Other cases (existing logic)
          data.forEach((item) => {
            // Handle headerMetaTag
            if (item.headerMetaTag) {
              appendMetaTagsToHead(item.headerMetaTag, 'headerMetaTag');
            }
  
            // Handle metaTag
            if (item.metaTag) {
              appendMetaTagsToHead(item.metaTag, 'metaTag');
            }
          });
        }
      } else {
        console.error(`No meta tags found in the response from ${apiUrl}`);
      }
    } catch (error) {
      console.error(`Failed to fetch and update meta tags from ${apiUrl}:`, error);
    }
  }
  
  // Helper function to append meta tags to the head section
  function appendMetaTagsToHead(metaTagString, source) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(metaTagString, 'text/html');
    const metaElements = doc.head.childNodes;
  
    metaElements.forEach((metaElement) => {
      if (metaElement.nodeType === Node.ELEMENT_NODE) {
        document.head.appendChild(metaElement);
        console.log(`Meta tag from ${source} added to head:`, metaElement.outerHTML);
      }
    });
  }
  
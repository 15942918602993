import React, { useState, useEffect } from "react";
import Image from "./Image.js";
import Cards from "./Cards.js";
import { Navbar2 } from "../Navbar2.jsx";
import { Footer } from "../Footer.jsx";
import NavbarMobile from "../NavbarMobile.jsx";
import { NavbarTab } from "../NavbarTab.jsx";
import { Banner } from "./Banner.jsx";
import Banner2 from "./Banner2.jsx";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  const [viewport, setViewport] = useState("large");
  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    const fetchMetaTags = async () => {
      try {
        const response = await fetch("https://api.bhk99.com/api/howitworksmetatags");
        const data = await response.json();
        setMetaTags(data || []);
        console.log("Fetched How It Works page meta tags:", data);
      } catch (error) {
        console.error("Error fetching meta tags for How It Works page:", error);
      }
    };

    fetchMetaTags();


    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Other Components */}
     
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <Image />
      <Cards />
      <Banner2 />
      <Banner />
      {viewport === "mobile" && <NavbarMobile />}
      <Footer />
      {/* Add other components here */}
    </>
  );
};

export default HowItWorks;
